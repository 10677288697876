import {
  CommerceItemResponse, CommerceType, CommerceItemHashTag,
  HashTagObj,
  CommercePermission,
  PayingOption,
  SurveyOption,
  CommerceItemCategory,
  NotificationPublishType,
  ReviewStatusType,
  PaymentStatusType,
  CommerceApplicationResponse,
  CommerceAttendeeResponse,
  CommerceAttendanceRecordResponse,
  CommerceReviewHistoryResponse,
  ApplicationStatusType,
  ApplyStatusType,
  RedemptionRecordResponse,
} from 'app/models/CommerceItem';
import { Filter, ListResult, PublishStatusType } from 'app/models/Common';
import { DriveType, FolderType } from 'app/models/Drive';
import { FromSVType } from 'app/models/Shop';

import Axios from '.';

/**
 * List All Commerce Item By Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/admin)
 */

export interface GetCommerceItemListParams extends Filter<CommerceItemResponse> {
  commerceType?: CommerceType;
  publishStatus?: PublishStatusType;
  reviewStatus?: ReviewStatusType;
  isCountingDown?: boolean;
  title?: string;
  startDate?: string;
  endDate?: string;
  shopId?: number;
  fromSV?: FromSVType;
  commerceItemCategoryId?: number;
  hashTag?: string;
  hasPendingApplyStatus?: boolean;
}

export const getCommerceItemList = async (params: GetCommerceItemListParams) => {
  const { data } = await Axios.get<ListResult<CommerceItemResponse>>('/commerces/items/admin', { params });
  return data;
};

/**
 * Get One Commerce By CommerceId, Optional Login (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/-commerceItemId-)
 */

export const getOneCommerceItem = async (commerceItemId: number | string) => {
  const { data } = await Axios.get<CommerceItemResponse>(`/commerces/items/${commerceItemId}`);
  return data;
};

interface CommonCommercePurchaseOptionsProps {
  commercePurchaseOptionId?: number
  image: string
  commercePurchaseOptionTitle?: string
  commercePurchaseOptionSubtitle?: string
  payingOption: PayingOption;
  commercePurchaseOptionQuota?: number
  commercePurchaseOptionMin?: number | null
  commercePurchaseOptionMax?: number | null
  commercePurchaseOptionPerUserMin?: number | null
  commercePurchaseOptionPerUserMax?: number | null
  pointEarnAfterApply?: number | null
  pointEarnAfterCheckIn?: number | null
  commercePurchaseOptionMediaFolderType?: FolderType | null
  commercePurchaseOptionMediaDriveType?: DriveType | null
  commercePurchaseOptionMediaPath?: string
  surveyOption: SurveyOption
  surveyId?: number | null;
  isAllowWaitList?: boolean;
  waitListQuota?: number | null;
  isAllowGuest?: boolean;
  ordering: number;
}

interface CreateCommerceScheduleProps {
  commerceScheduleId?: number;
  commerceScheduleTitle?: string;
  startTime: string;
  endTime: string;
  location?: string | null;
  isOnline?: boolean;
  zoomRoomNumber?: string | null;
  zoomRoomPassword?: string | null;
  pointEarnAfterCheckIn?: number | null;
  afterCheckInSurveyId?: number | null;
  materialFolderType?: FolderType | null;
  materialDriveType?: DriveType | null;
  materialPath?: string | null;
  materialHiddenPath?: string | null;
  ordering: number;
}

interface CreateEventDetailProps {
  eventDetailId?: number;
  CommerceSchedule: CreateCommerceScheduleProps;
}

interface CreateCommerceItemCommonProps {
  shopId: number;
  commerceItemCategoryId: number;
  CommerceItemHashTags: HashTagObj[];
  title: string;
  coverPhoto: string;
  images: string[];
  remarks?: string;
  subtitle?: string;
  description: string;
  mediaFolderType?: FolderType | null;
  mediaDriveType?: DriveType | null;
  mediaPath?: string;
  attachmentFolderType?: FolderType | null;
  attachmentDriveType?: DriveType | null;
  attachmentPath?: string | null;
  attachmentHiddenRoot?: string | null;
  publishedDate?: string | null;
  publishEndDate?: string | null;
  CommercePermissions: CommercePermission[];
  afterPaymentAttachmentDriveType?: DriveType | null;
  afterPaymentAttachmentFolderType?: FolderType | null;
  afterPaymentAttachmentPath?: string | null;
  afterPaymentAttachmentHiddenRoot?: string | null;
  reviewStatus?: ReviewStatusType;
  ScheduledNotification?: CreateCommerceItemPushNotificationProps;
}

interface CreateCommerceItemPushNotificationProps {
  publishType: NotificationPublishType;
  scheduledTime?: string | null;
  title?: string;
  content?: string | null;
  image?: string | null;
  tags?: string[];
  scheduledNotificationId?: number;
}

interface CreateEventPurchaseOptionProps extends CommonCommercePurchaseOptionsProps {
  isAllowGuest: boolean
}

interface CreateEventCommerceItemProps extends CreateCommerceItemCommonProps {
  commerceType: '活動';
  caution?: string;
  paidReminder?: string;
  EventDetail: CreateEventDetailProps;
  isCharity?: boolean;
  acceptDonation?: boolean;
  surveyId?: number | null;
  perOrderMin?: number | null;
  perOrderMax?: number | null;
  perUserTotalMin?: number | null;
  perUserTotalMax?: number | null;
  openForSaleDate?: string | null;
  discontinueDate?: string | null;
  CommercePurchaseOptions: CreateEventPurchaseOptionProps[];
}

interface CreateCourseDetailProps {
  courseDetailId?: number;
  courseCode: string;
  contactPerson: string;
  contactPhoneNumber: string;
  pointEarnAfterApply?: number | null;
  afterPaymentAttachmentFolderType?: FolderType | null;
  afterPaymentAttachmentDriveType?: DriveType | null;
  afterPaymentAttachmentPath?: string | null;
  afterPaymentAttachmentHiddenRoot?: string | null;
  CommerceSchedules: CreateCommerceScheduleProps[];
  isApplyPerLesson: boolean;
}

interface CreateCoursePurchaseOptionProps extends CommonCommercePurchaseOptionsProps {
  isAllowMember?: boolean;
  isAllowPreAgent?: boolean;
  preAgentSurveyOption?: SurveyOption;
  preAgentSurveyId?: number | null;
}

interface CreateCourseCommerceItemProps extends CreateCommerceItemCommonProps {
  commerceType: '課程';
  caution?: string;
  CourseDetail: CreateCourseDetailProps;
  CommercePurchaseOptions: CreateCoursePurchaseOptionProps[];
  acceptDonation?: boolean;
  surveyId?: number | null;
  perOrderMin?: number | null;
  perOrderMax?: number | null;
  perUserTotalMin?: number | null;
  perUserTotalMax?: number | null;
  openForSaleDate?: string | null;
  discontinueDate?: string | null;
}

interface CreateProductCommerceItemProps extends CreateCommerceItemCommonProps {
  commerceType: '貨品';
  caution?: string;
  paidReminder?: string;
  isCharity?: boolean;
  acceptDonation?: boolean;
  surveyId?: number | null;
  perOrderMin?: number | null;
  perOrderMax?: number | null;
  perUserTotalMin?: number | null;
  perUserTotalMax?: number | null;
  openForSaleDate?: string | null;
  discontinueDate?: string | null;
  redemptionDueDate?: string | null;
  CommercePurchaseOptions: CommonCommercePurchaseOptionsProps[];
}

interface CreateNewsCommerceItemProps extends CreateCommerceItemCommonProps {
  commerceType: '資訊';
  surveyId?: number | null;
}

export type CreateCommerceItemProps =
  CreateEventCommerceItemProps
  | CreateCourseCommerceItemProps
  | CreateProductCommerceItemProps
  | CreateNewsCommerceItemProps;

/**
 * Create A Commerce Item, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items)
 */

export const createOneCommerceItem = async (params: { CommerceItem: CreateCommerceItemProps }) => {
  const { data } = await Axios.post<CommerceItemResponse>('/commerces/items', params);
  return data;
};

/**
 * Update One Commerce Item By CommerceItemId, Admin Only (-)
 */

export const updateOneCommerceItem = async (
  commerceItemId: number | string,
  params: { CommerceItem: CreateCommerceItemProps },
) => {
  const { data } = await Axios.patch<CommerceItemResponse>(`/commerces/items/${commerceItemId}`, params);
  return data;
};

/**
 * Delete One Commerce By CommerceId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#delete-/v2/commerces/items/-commerceItemId-)
 */

export const deleteOneCommerceItem = async (commerceItemId: number | string) => {
  await Axios.delete(`/commerces/items/${commerceItemId}`);
};

export interface GetUniqueCommerceItemHashTagParams {
  hashTagPrefix?: string
}

export interface GetCommerceItemCategoryListParams extends Filter<CommerceItemCategory> {}

/**
 * List All Commerce Item Category (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/itemCategory)
 */

export const getCommerceItemCategoryList = async (params: GetCommerceItemCategoryListParams) => {
  const { data } = await Axios.get<ListResult<CommerceItemCategory>>('/commerces/itemCategory', { params });
  return data;
};

/**
 * Get One Commerce Item Category By commerceItemCategoryId (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/itemCategory/-commerceItemCategoryId-)
 */

export const getOneCommerceItemCategory = async (commerceItemCategoryId: number) => {
  const { data } = await Axios.get<CommerceItemCategory>(`/commerces/itemCategory/${commerceItemCategoryId}`);
  return data;
};

/**
 * List All Unique Commerce Item HashTag (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/hashTag)
 */

export const getUniqueCommerceItemHashTag = async (params: GetUniqueCommerceItemHashTagParams) => {
  const { data } = await Axios.get<CommerceItemHashTag[]>('/commerces/items/hashTag', { params });
  return data;
};

/**
 * Get Permitted User Count, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items/permittedUserCount)
 */

export const getPermittedUserCount = async (params: { CommercePermissions: CommercePermission[] }) => {
  const { data } = await Axios.post<{ count: number }>('/commerces/items/permittedUserCount', params);
  return data;
};

export interface GetCommerceApplicationListParams extends Filter<CommerceItemResponse> {
  search?: string;
  commerceItemId?: number;
  commerceItemCategoryId?: number;
  paymentStatuses?: PaymentStatusType;
  applicationStatuses?: ApplicationStatusType | ApplicationStatusType[];
  userId?: number
}

/**
 * List All Commerce Application, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/application)
 */

export const getCommerceApplicationList = async (params: GetCommerceApplicationListParams) => {
  const { data } = await Axios.get<ListResult<CommerceApplicationResponse>>('/commerces/items/application', { params });
  return data;
};

/**
 * List All Commerce Application, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/application)
 */

export const getOneCommerceApplication = async (commerceApplicationId: number) => {
  const { data } = await Axios.get<CommerceApplicationResponse>(
    `/commerces/items/application/${commerceApplicationId}`,
  );
  return data;
};

export interface GetCommerceItemAttendeeListParams extends Filter<CommerceAttendeeResponse> {
  commerceItemId: number,
  search?: string;
  applicationStatuses?: ApplicationStatusType[];
  paymentStatuses?: PaymentStatusType;
}

/**
 * List All Commerce Attendees By commerceItemId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/-commerceItemId-/attendees)
 */

export const getCommerceItemAttendeeList = async (
  { commerceItemId, ...params }: GetCommerceItemAttendeeListParams,
) => {
  const { data } = await Axios.get<ListResult<CommerceAttendeeResponse>>(
    `/commerces/items/${commerceItemId}/attendees`,
    { params },
  );
  return data;
};

export interface GetCommerceItemUnAttendedListParams extends Filter<CommerceAttendanceRecordResponse> {
  commerceScheduleId: number,
  search?: string;
  applicationStatuses?: ApplicationStatusType;
  paymentStatuses?: PaymentStatusType;
}

/**
 * List All Unattended Commerce Attendees By commerceItemId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/-commerceItemId-/attendees)
 */

export const getCommerceItemUnAttendeeList = async (
  { commerceScheduleId, ...params }: GetCommerceItemUnAttendedListParams,
) => {
  const { data } = await Axios.get<ListResult<CommerceAttendeeResponse>>(
    `/commerces/items/commerceSchedule/${commerceScheduleId}/unattended`,
    { params },
  );
  return data;
};

export interface GetCommerceAttendanceRecordListParams extends Filter<CommerceAttendanceRecordResponse> {
  commerceScheduleId: number,
  userId?: number;
  preAgentId?: number;
  hasCheckInRecord?: boolean;
}

/**
 * List All Commerce Attendees By commerceScheduleId, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/commerceSchedule/-commerceScheduleId-/attendanceRecord)
 */

export const getCommerceAttendanceRecordList = async (
  {
    commerceScheduleId,
    ...params
  }: GetCommerceAttendanceRecordListParams,
) => {
  const { data } = await Axios.get<ListResult<CommerceAttendeeResponse>>(
    `/commerces/items/commerceSchedule/${commerceScheduleId}/attendanceRecord`,
    { params },
  );
  return data;
};

export interface GetCommerceReviewHistoryListParams extends Filter<CommerceReviewHistoryResponse> {
  commerceItemId?: number;
  commerceBannerId?: number;
  adminId?: number;
  dateFrom?: string;
  dateTo?: string;
  applyStatus?: ApplyStatusType;
}

/**
 * List all the reviewHistory (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/reviewHistory)
 */

export const getCommerceReviewHistoryList = async (
  params : GetCommerceReviewHistoryListParams,
) => {
  const { data } = await Axios.get<ListResult<CommerceReviewHistoryResponse>>(
    '/commerces/reviewHistory',
    { params },
  );
  return data;
};

/**
 * Count All Commerce Item (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items)
 */

export const countCommerceItem = async (params: GetCommerceItemListParams) => {
  const { data } = await Axios.get<{ count: number }>('/commerces/items/count', { params });
  return data;
};

export interface CreateOrUpdateAttendanceRecordParams {
  attendanceRecordId?: number
  commerceAttendeeId: number
  commerceScheduleId: number
  checkInDateTime?: string | null
  checkOutDateTime?: string | null
}

/**
 * Create Or Update One Attendance Record, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items/application/attendanceRecord)
 */

export const createOrUpdateOneAttendanceRecord = async (params: CreateOrUpdateAttendanceRecordParams) => {
  const { data } = await Axios.post<CommerceAttendanceRecordResponse>('/commerces/items/application/attendanceRecord', params);
  return data;
};

export interface BatchCreateOrUpdateAttendanceRecordParams {
  commerceAttendeeIds: number[]
  commerceScheduleId: number
  checkInDateTime?: string | null
  checkOutDateTime?: string | null
}

/**
 * Create Or Update Attendance Record In Batch, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items/application/attendanceRecordBatch)
 */

export const batchCreateOrUpdateOneAttendanceRecord = async (params: BatchCreateOrUpdateAttendanceRecordParams) => {
  const { data } = await Axios.post('/commerces/items/application/attendanceRecordBatch', params);
  return data;
};

/**
 * Cancel One Event Application By CommerceApplicationId, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#put-/v2/commerces/items/application/-commerceApplicationId-/cancel)
 */

export const cancelOneCommerceApplication = async (commerceApplicationId: number) => {
  await Axios.put(`/commerces/items/application/${commerceApplicationId}/cancel`);
};

/**
 * Accept waiting list Application. Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items/application/-commerceApplicationId-/acceptWaitingList)
 */

export const acceptWaitingListApplication = async (commerceApplicationId: number) => {
  await Axios.post(`/commerces/items/application/${commerceApplicationId}/acceptWaitingList`);
};

/**
 * Apply One ReviewHistory, App User Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/reviewHistory/-reviewHistoryId-/apply)
 */

export const applyOneReviewHistory = async (reviewHistoryId: number) => {
  await Axios.post(`/commerces/reviewHistory/${reviewHistoryId}/apply`);
};

/**
 * Delete One ReviewHistory, App User Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/reviewHistory/-reviewHistoryId-/reject)
 */

export const rejectOneReviewHistory = async (reviewHistoryId: number) => {
  await Axios.post(`/commerces/reviewHistory/${reviewHistoryId}/reject`);
};

export interface GetRedemptionRecordListParams extends Filter<RedemptionRecordResponse> {
  commerceItemId?: number;
  commerceApplicationId?: number;
  commercePurchaseOptionId?: number;
  shopId?: number;
}

/**
 * List Redemption Record, Login Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#get-/v2/commerces/items/redemptionRecords)
 */

export const getRedemptionRecordList = async (params: GetRedemptionRecordListParams) => {
  const { data } = await Axios.get<ListResult<RedemptionRecordResponse>>('/commerces/items/redemptionRecords', { params });
  return data;
};

interface RedeemApplicationOptionItem {
  commercePurchaseOptionId: number;
  numberOfOption: number;
}

export interface RedeemOneCommerceApplicationParams {
  commerceApplicationOptions: RedeemApplicationOptionItem[]
}

/**
 * Redeem One Commerce Application By code, Admin Only (https://svgroup-app-v2-dev.appicidea.com/v2/commerces/doc/v2#post-/v2/commerces/items/application/product/-code-/redemption)
 */

export const redeemOneCommerceApplication = async (code: string, params: RedeemOneCommerceApplicationParams) => {
  await Axios.post(`/commerces/items/application/product/${code}/redemption`, params);
};
