import dayjs from 'dayjs';

import { IMAGE_URL } from 'app/configs';

export const getImageURL = (imgSrc: string, size?: number | 'original') => {
  if (imgSrc && imgSrc.includes('https')) {
    return imgSrc;
  }
  if (imgSrc) {
    return `${IMAGE_URL}/${size || '320'}/${imgSrc}`;
  }
  return '';
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatPrice = (price: number) => {
  if (price) {
    const dollarUS = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return dollarUS.format(price);
  }
  return '-';
};

export const formatNumber = (value?: number | null): string => {
  if (value || value === 0) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '-';
};

export const formatTime = (value: string): string => dayjs(value, 'HH:mm:ss').format('HH:mm');

export const isLocalhost = () => window.location.hostname === 'localhost';

export const getVideoDownloadLink = (videoSrc?: string | null) => {
  if (!videoSrc) {
    return '';
  }
  const isM3U8 = videoSrc.includes('.m3u8');
  if (!isM3U8) {
    return videoSrc;
  }
  const strArr = videoSrc.split('/');
  const src = strArr[strArr.length - 1];
  return getImageURL(src.replace('.m3u8', '.mp4'), 'original');
};

export const toServerPrice = (price: number): number => {
  if (price) {
    return price * 100;
  }
  return 0;
};

export const toClientPrice = (price: number): number => {
  if (price) {
    return price / 100;
  }
  return 0;
};
