import dayjs from 'dayjs';
import { z } from 'zod';

import {
  DisplayPermission, NotificationPublishType, PermissionUserType,
} from 'app/models/CommerceItem';
import {
  MemberLevelType, UserJobTitleType,
} from 'app/models/User';

export const COMMERCE_ITEM_PERMISSION_FORM_SCHEMA = z.object({
  CommercePermissions: z
    .array(
      z.object({
        commercePermissionId: z
          .number()
          .optional(),
        userType: z
          .custom<PermissionUserType>()
          .nullable(),
        memberLevel: z
          .custom<MemberLevelType>()
          .nullable(),
        userTag: z
          .string()
          .nullable(),
        branchName: z
          .string()
          .nullable(),
        family: z
          .string()
          .nullable(),
        jobTitle: z
          .custom<UserJobTitleType>()
          .nullable(),
      }),
    ),
  displayPermission: z
    .custom<DisplayPermission>(),
});

export type CommerceItemPermissionFormDataType = z.infer<typeof COMMERCE_ITEM_PERMISSION_FORM_SCHEMA>;

export const INITIALIZED_COMMERCE_ITEM_PERMISSION: CommerceItemPermissionFormDataType['CommercePermissions'][number] = {
  commercePermissionId: undefined,
  userType: null,
  memberLevel: null,
  userTag: null,
  branchName: null,
  family: null,
  jobTitle: null,

};

export const COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA = z
  .object({
    publishType: z
      .custom<NotificationPublishType>(),
    scheduledTime: z
      .date()
      .nullable(),
    title: z
      .string(),
    content: z
      .string()
      .nullable(),
    scheduledNotificationId: z
      .number()
      .optional(),
  })
  .refine(({ publishType, scheduledTime }) => publishType === 'instant' || scheduledTime, {
    message: '預約發佈時必須填寫發佈日期',
    path: ['scheduledTime'],
  })
  .refine(({ title, content }) => {
    if (content) {
      return !!title;
    }
    return true;
  }, { message: '必填', path: ['title'] });

export type CommerceItemNotificationInfoFormDataType = z.infer<typeof COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA>;

export const INITIALIZED_COMMERCE_ITEM_NOTIFICATION_INFO : CommerceItemNotificationInfoFormDataType = {
  publishType: 'instant',
  scheduledTime: null,
  title: '',
  content: '',
};

export const COMMERCE_ITEM_COMMON_FORM_SCHEMA = z
  .object({
    permission: COMMERCE_ITEM_PERMISSION_FORM_SCHEMA,
    pushNotification: COMMERCE_ITEM_NOTIFICATION_INFO_FORM_SCHEMA,
  });

export type CommerceItemCommonFormDataType = z.infer<typeof COMMERCE_ITEM_COMMON_FORM_SCHEMA>;

export const COMMERCE_ITEM_CHECK_IN_OUT_FORM_SCHEMA = z
  .object({
    checkInDateTime: z
      .date()
      .nullable(),
    checkOutDateTime: z
      .date()
      .nullable(),
  })
  .refine(({ checkInDateTime }) => checkInDateTime, { message: '必填', path: ['checkInDateTime'] })
  .refine(
    ({ checkInDateTime, checkOutDateTime }) => !checkInDateTime
    || !checkOutDateTime
    || dayjs(checkInDateTime).isBefore(checkOutDateTime),
    { message: '出席時間要比離開時間早', path: ['checkInDateTime'] },
  );

export type CommerceItemCheckInOutFormDataType = z.infer<typeof COMMERCE_ITEM_CHECK_IN_OUT_FORM_SCHEMA>;

export const IMAGE_RELATED_FIELD = ['coverPhoto', 'image'];

export const RICH_TEXT_RELATED_FIELD = ['description', 'caution', 'paidReminder'];

export const OBJECT_RELATED_FIELD = ['CommercePurchaseOptions', 'CommerceSchedules', 'ScheduledNotification'];

export const REDEMPTION_FORM_SCHEMA = z
  .object({
    commerceApplicationOptions: z
      .array(
        z.object({
          max: z.number(),
          numberOfOption: z
            .string()
            .regex(/^\d+$/, '請輸入正整數')
            .or(z.literal('')),
          commercePurchaseOptionId: z
            .number(),
        })
          .refine(({ max, numberOfOption }) => !numberOfOption || parseInt(numberOfOption, 10) <= max, {
            message: '超出可換領數量',
            path: ['numberOfOption'],
          }),
      ),
  });

export type RedemptionFormDataType = z.infer<typeof REDEMPTION_FORM_SCHEMA>;
