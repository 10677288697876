import dayjs from 'dayjs';
import { TbCoinFilled } from 'react-icons/tb';
import { z } from 'zod';

import { CreateCommerceItemProps } from 'app/apis/CommerceServices';
import { CommerceItemCommonFormDataType, INITIALIZED_COMMERCE_ITEM_NOTIFICATION_INFO, CommerceItemPermissionFormDataType } from 'app/constants/CommerceItem';
import { CommerceItemResponse, PaymentOptionType } from 'app/models/CommerceItem';
import { FormType } from 'app/models/Common';
import { formatNumber, toClientPrice } from 'app/utils';

export const checkPaymentType = (
  cash: number | undefined,
  point: number | undefined,
): PaymentOptionType => {
  if (cash && point) {
    return 'cashAndPoint';
  }
  if (cash) {
    return 'cash';
  }
  if (point) {
    return 'point';
  }
  return 'free';
};

export const transformToCommonCommerceItemFormData = ({ CommercePermissions, ScheduledNotification }: Pick<CommerceItemResponse, 'CommercePermissions' | 'ScheduledNotification'>, type?: FormType): CommerceItemCommonFormDataType => ({
  permission: {
    CommercePermissions: CommercePermissions.map(({
      commercePermissionId,
      userType,
      memberLevel,
      userTag,
      branchName,
      family,
      jobTitle,
    }) => ({
      commercePermissionId: type === 'edit' || type === 'view' ? commercePermissionId : undefined,
      userType: userType || null,
      memberLevel: memberLevel || null,
      userTag: userTag || null,
      branchName: branchName || null,
      family: family || null,
      jobTitle: jobTitle || null,
    })),
    displayPermission: 'onlySpecifiedUserCanView',
  },
  pushNotification: {
    ...INITIALIZED_COMMERCE_ITEM_NOTIFICATION_INFO,
    publishType: ScheduledNotification?.publishType ?? 'instant',
    scheduledTime: ScheduledNotification?.scheduledTime
      ? dayjs(ScheduledNotification?.scheduledTime).toDate()
      : null,
    title: ScheduledNotification?.title || '',
    content: ScheduledNotification?.content || '',
    scheduledNotificationId: type === 'edit' ? ScheduledNotification?.scheduledNotificationId : undefined,
  },
});

export const transformToCommonCommerceItemParams = ({
  permission: {
    CommercePermissions,
  },
  pushNotification: {
    publishType,
    scheduledTime,
    title: notificationTitle,
    content,
    scheduledNotificationId,
  },
}: CommerceItemCommonFormDataType, type?: FormType): Pick<CreateCommerceItemProps, 'CommercePermissions' | 'ScheduledNotification'> => ({
  CommercePermissions: CommercePermissions.filter((permission) => !Object.values(permission).every((x) => !x)).map(({
    userType, memberLevel, userTag, branchName, family, jobTitle, commercePermissionId,
  }) => ({
    commercePermissionId: type === 'edit' || type === 'view' ? commercePermissionId : undefined,
    userType: userType || null,
    memberLevel: memberLevel || null,
    userTag: userTag || null,
    branchName: branchName || null,
    family: family || null,
    jobTitle: jobTitle || null,
  })),
  ScheduledNotification: notificationTitle ? {
    publishType,
    scheduledTime: dayjs(scheduledTime || undefined).toISOString(),
    title: notificationTitle || undefined,
    content: content || undefined,
    scheduledNotificationId,
  } : undefined,
});

export const validatePermission = (permission: CommerceItemPermissionFormDataType, ctx: z.RefinementCtx) => {
  if (!permission.CommercePermissions
    .map(({ commercePermissionId, ...permissionRest }) => ({ ...permissionRest }))
    .some(
      (permissionItem) => Object.values(permissionItem).some((val) => val),
    )) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: '最少選擇一項',
      path: ['permission', 'CommercePermissions', ''],
    });
  }
};

export const calculationPayingTotal = (cash?: number, point?: number, count = 1) => {
  if (cash && point) {
    return (
      <div className="flex flex-nowrap items-center gap-[4px]">
        $
        {formatNumber(toClientPrice(cash) * count)}
        {' '}
        +
        <TbCoinFilled fill="#FFA021" className="size-[16px]" />
        {point * count}
      </div>
    );
  } if (point) {
    return (
      <div className="flex flex-nowrap items-center gap-[4px]">
        <TbCoinFilled fill="#FFA021" className="size-[16px]" />
        {point * count}
      </div>
    );
  }
  return (
    <div className="flex flex-nowrap items-center">
      $
      {formatNumber(toClientPrice(cash || 0) * count)}
    </div>
  );
};
